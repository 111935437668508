import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import styled from "styled-components"
// import Text from "../Text/Text"
// import Heading from "../Heading/Heading"
// import Button from "../Button/Button"
// import { Box, Flex } from "rebass/styled-components"
// import { theme } from "../../common/theme"
// import { Container } from "../Grid"
import { StyleSheet, css } from "aphrodite";
import {
  Copy,
  variables,
  ButtonTest,
  Icon,
} from "@website2018/da-dobsonville";

const styles = StyleSheet.create({
  styledNotice: {
    transition: `all ${variables.timing.base} ease`,
  },
  notice: {
    position: "relative",
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  styledText: {
    paddingTop: "16px",
    "& > a": {
      textDecoration: "underline",
      transition: `all ${variables.timing.base} ease`,
      ":hover": {
        textDecoration: "underline",
      },
    },
  },
  close: {
    position: "absolute",
    top: "0px",
    right: "8px",
    ":hover": {
      cursor: "pointer",
      opacity: "0.5",
    },
  },
});

const STATE_KEY = "showNotice";
const isClient = typeof window !== "undefined";

const Notice = (props) => {
  const { notice_isActive, notices, notice_bg, notice_button, notice_color } =
    props;

  let storage = isClient
    ? JSON.parse(window.sessionStorage.getItem(STATE_KEY))
    : null;

  if (storage === null) {
    storage = notice_isActive;
  }

  const [isOpen, setIsOpen] = useState(storage);

  if (notice_isActive === "false") return null;

  return (
    <div
      className={`${css(styles.styledNotice)}`}
      style={{
        display: isOpen ? "block" : "none",
        opacity: isOpen ? 1 : 0,
      }}
    >
      {notices.map((i, index) => {
        return (
          <div
            key={index}
            className={`${css(styles.notice)}`}
            style={{ backgroundColor: notice_bg }}
          >
            <div className="container">
              <div className={`${css(styles.wrapper)}`}>
                <div
                  className={`${css(styles.close)}`}
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <Icon
                    fs={"24px"}
                    name={variables.icons.close}
                    color={notice_color}
                  />
                </div>
                <div className={`${css(styles.styledText)}`}>
                  {i.notice_title && (
                    <Copy
                      color={notice_color}
                      size={"medium"}
                      html={i.notice_title}
                      mt="small"
                      mb="small"
                      weight="medium"
                    />
                  )}
                  {i.notice_text && (
                    <Copy
                      color={notice_color}
                      size={"medium"}
                      html={i.notice_text}
                      mt="small"
                      mb="small"
                    />
                  )}
                </div>
                {i.notice_button.url && (
                  <a
                    href={i.notice_button.url}
                    title={i.notice_button.title}
                    target={i.notice_button.url}
                  >
                    <ButtonTest color={notice_button} size="small">
                      {i.notice_button.title}
                    </ButtonTest>
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Notice;

Notice.defaultProps = {
  /**  Children renders the Title */
  notice_isActive: false,
  notices: [],
  notice_color: null,
  notice_bg: null,
  notice_button: null,
};

Notice.propTypes = {
  notice_isActive: PropTypes.string,
  notices: PropTypes.array,
  notice_color: PropTypes.string,
  notice_bg: PropTypes.string,
  notice_button: PropTypes.string,
};
