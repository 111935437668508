import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { Link } from "react-router-dom";
import SettingsProvider from '../../containers/Settings/SettingsProvider';
import {
  variables,
  Heading,
  NavIcon,
  MoreLink,
} from "@website2018/da-dobsonville";

const ContactInfo = (props, context) => {
  const { title, iconColor, color } = props;
  const { spacing, colors } = variables;
  const {
    settings: { social },
  } = context;

  const styles = StyleSheet.create({
    menu: {
      margin: spacing.space0,
      padding: spacing.space0,
    },
  });

  return (
    <Fragment>
      <Heading
        color={color}
        size={"tiny"}
        weight={"bold"}
        letterSpacing={"small"}
        mb={"tiny"}
        uppercase
      >
        {title}
      </Heading>
      <div className={css(styles.menu)}>
        <a
          href={social.telNumber}
          target="_blank"
          rel="noopener noreferrer"
          title={social.telNumber}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialMobile}
            iconName="telephone-fill"
          >
            {social.telText}
          </NavIcon>
        </a>
        {social.whatsapp && (
          <a
            href={social.whatsapp}
            target="_blank"
            rel="noopener noreferrer"
            title={social.whatsapp}
          >
            <NavIcon
              iconType="bootstrap"
              color={color}
              iconColor={iconColor ? iconColor : colors.socialWhatsApp}
              iconName="whatsapp"
            >
              {social.whatsappText}<br />
              <small>(text messages only)</small>
            </NavIcon>
          </a>
        )}
        <a
          href={social.instagram}
          target="_blank"
          rel="noopener noreferrer"
          title={social.instagram}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialInstagram}
            iconName="instagram"
          >
            Instagram
          </NavIcon>
        </a>
        <a
          href={social.tiktok}
          target="_blank"
          rel="noopener noreferrer"
          title={social.tiktok}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : "#EE1D52"}
            iconName="tiktok"
          >
            Tiktok
          </NavIcon>
        </a>
        <a
          href={social.facebook}
          target="_blank"
          rel="noopener noreferrer"
          title={social.facebook}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialFacebook}
            iconName="facebook"
          >
            Facebook
          </NavIcon>
        </a>
        <a
          href={social.twitter}
          target="_blank"
          rel="noopener noreferrer"
          title={social.twitter}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialTwitter}
            iconName="twitter-x"
          >
            X (Twitter)
          </NavIcon>
        </a>
        <a
          href={social.youtube}
          target="_blank"
          rel="noopener noreferrer"
          title={social.youtube}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialYoutube}
            iconName="youtube"
          >
            YouTube
          </NavIcon>
        </a>
        <a
          href={social.email}
          target="_blank"
          rel="noopener noreferrer"
          title={social.email}
        >
          <NavIcon
            iconType="bootstrap"
            color={color}
            iconColor={iconColor ? iconColor : colors.socialEmail}
            iconName="envelope-fill"
          >
            Email
          </NavIcon>
        </a>
        <Link to={social.contact}>
          <MoreLink color={color} uppercase>
            View all Info
          </MoreLink>
        </Link>
      </div>
    </Fragment>
  );
};

ContactInfo.defaultProps = {
  title: "Contact Details",
  color: variables.colors.brandBlue,
  iconColor: null,
};

ContactInfo.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  iconColor: PropTypes.string,
};

ContactInfo.contextTypes = {
  settings: PropTypes.shape(SettingsProvider.settingDefinition).isRequired,
};

export default ContactInfo;
