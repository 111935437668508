

import App from './App';
import React from 'react';
import { hydrate } from 'react-dom';
import { loadableReady } from "@loadable/component";

import { BrowserRouter } from "react-router-dom";
import fetch from 'unfetch';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import SettingsProvider from './containers/Settings/SettingsProvider';
import { StyleSheet } from 'aphrodite';
import { createUploadLink } from 'apollo-upload-client'


StyleSheet.rehydrate(window.__APHRODITE_STATE__);

const client = new ApolloClient({
  link: createUploadLink({ uri: process.env.RAZZLE_GQL_HOST, fetch }),
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  ssrForceFetchDelay: 100, // in milliseconds
});

const WrappedApp = (
  <HelmetProvider>
    <ApolloProvider client={client}>
      <SettingsProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SettingsProvider>
    </ApolloProvider>
  </HelmetProvider>
);

loadableReady().then(() => {
  hydrate(WrappedApp, document.getElementById("root"));
});

if (module.hot) {
  module.hot.accept();
}
