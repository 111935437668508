import { Component } from "react";
import PropTypes from "prop-types";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { Loading } from '@website2018/da-dobsonville';

class SettingsProvider extends Component {
  static propTypes = {
    setting: PropTypes.shape(SettingsProvider.settingDefinition),
  };

  static settingDefinition = {
    social: PropTypes.shape(SettingsProvider.socialDefinition).isRequired,
  };

  static socialDefinition = {
    facebook: PropTypes.string.isRequired,
    twitter: PropTypes.string.isRequired,
    youtube: PropTypes.string.isRequired,
    instagram: PropTypes.string.isRequired,
    tiktok: PropTypes.string.isRequired,
    telText: PropTypes.string.isRequired,
    telNumber: PropTypes.string.isRequired,
    whatsapp: PropTypes.string.isRequired,
    whatsappText: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
  };

  static defaultProps = {
    settings: {},
  };

  static childContextTypes = {
    settings: PropTypes.shape(SettingsProvider.settingDefinition).isRequired,
  };

  getChildContext() {
    return { settings: this.props.setting };
  }

  render() {
    if (this.props.loading) return <Loading />
    return this.props.children;
  }
}

const SETTINGS_QUERY = gql`
  query getSetting {
    setting {
      social {
        facebook
        twitter
        youtube
        instagram
        tiktok
        telText
        telNumber
        whatsapp
        whatsappText
        email
        contact
      }
    }
  }
`;

const withSettings = graphql(SETTINGS_QUERY, {
  props: ({ data, post }) => ({ ...data }),
});

export default withSettings(SettingsProvider);
