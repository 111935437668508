import React from "react";
import PropTypes from "prop-types";
import { graphql } from '@apollo/client/react/hoc';
import { gql } from "@apollo/client";
import { withRouter } from "react-router-dom";

class NavigationContainer extends React.Component {
  static propTypes = {
    render: PropTypes.func.isRequired
  };

  state = {
    open: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        open: false
      });
      window.scrollTo(0, 0);
    }
  }

  orderMenu = menu => {
    let items = [];

    items = menu.items.filter(i => i.parent === 0).map(i => {
      return {
        ...i,
        children: menu.items.filter(m => {
          return m.parent.toString() === i.id;
        })
      };
    });

    return {
      ...menu,
      items
    };
  };

  openMenu = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  render() {
    const { loading, menu, error } = this.props;
    if (loading || error) return null;
    return this.props.render(
      this.orderMenu(menu),
      this.openMenu,
      this.state.open
    );
  }
}

const MENU_QUERY = gql`
  query getMenu($menuId: String!) {
    menu(id: $menuId) {
      id
      name
      items {
        id
        title
        object_slug
        url
        parent
        classes
      }
    }
  }
`;

const withMenu = graphql(MENU_QUERY, {
  options: props => ({
    variables: {
      menuId: props.menuId.toString()
    }
  }),
  props: ({ data, menu }) => ({
    ...data
  })
});

export default withRouter(withMenu(NavigationContainer));
