import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { variables, FooterLegal } from "@website2018/da-dobsonville";
import NavigationContainer from '../../containers/Nav/NavigationContainer';
import FooterNav from './FooterNav'

const Footer = (props) => {
  const { colors, spacing } = variables;
  const styles = StyleSheet.create({
    menus:{
      paddingTop: spacing.space4,
      paddingBottom: spacing.space0,
      backgroundColor: colors.bgBlueLight,
      display: 'inline-block',
      width: '100%',
      "@media (min-width: 576px)": {
        paddingTop: spacing.space6,
        paddingBottom: spacing.space3,
      },
    },
    legal:{
      backgroundColor: colors.bgBlue,
      display: 'inline-block',
      width: '100%',
    }
  });

  return (
    <footer>
      <div className={css(styles.menus)}>
        <div className="container">
          <NavigationContainer
            menuId={"7"}
            render={ (menu) => <FooterNav menu={menu} /> }
          />
        </div>
      </div>
      
      <div className={css(styles.legal)}>
        <div className="container">
          <FooterLegal />
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  color: 'Copy',
}

export default Footer;
