import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import {
  variables,
  Logo,
  Heading,
  NavItem
} from "@website2018/da-dobsonville";
import { Link } from "react-router-dom";
import SettingsProvider from '../../containers/Settings/SettingsProvider';
import ContactInfo from './ContactInfo'
import Spacing from '../Spacing/Spacing'

const FooterNav = (props, context) => {
  const { colors, fontWeight, spacing } = variables;
  const { col } = props;

  const styles = StyleSheet.create({
    footerMenus: {
      marginBottom: spacing.space0,
      "@media (min-width: 576px)": {
        marginBottom: spacing.space3
      }
    },
    wrapper: {
      marginBottom: spacing.space3,
      "@media (min-width: 576px)": {
        marginBottom: spacing.space5
      }
    },
    menu: {
      margin: spacing.space0,
      padding: spacing.space0
    }
  });
  return (
    <div className={css(styles.footerMenus)}>
      <div className="row">
        <div className="col-md-5 ml-auto">
          <Spacing bottom size="space4">
            <Logo />
          </Spacing>

          <Heading color="white" size="large" mb="large">
            One Nation with One Future built on Freedom, Fairness, Opportunity
            and Diversity for All.
          </Heading>
        </div>
        <div className={`${css(styles.wrapper)} ${col}`}>
          <ContactInfo
            title={"Contact Details"}
            color={"white"}
            iconColor={"white"}
          />
        </div>
        {props.menu.items.map(menu => {
          return (
            <div className={`${css(styles.wrapper)} ${col}`} key={menu.id}>
              <Heading
                color={"white"}
                size={"tiny"}
                weight={"bold"}
                letterSpacing={"small"}
                mb={"tiny"}
                uppercase
              >
                {menu.title}
              </Heading>
              <ul className={css(styles.menu)}>
                {menu.children.map(item => (
                  <NavItem
                    key={item.id}
                    ml={spacing.space0}
                    mr={spacing.space0}
                    mb={spacing.space1}
                    display={"block"}
                    fontWeight={fontWeight.light}
                    color={colors.baseWhite}
                    title={item.title}
                  >
                    {item.object_slug ? (
                      <Link
                        to={`/${menu.object_slug}/${item.object_slug ||
                          item.url}`}
                      >
                        {item.title}
                      </Link>
                    ) : (
                      <a href={item.url} rel="noopener noreferrer" target="_blank">
                        {item.title}
                      </a>
                    )}
                  </NavItem>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
};

FooterNav.defaultProps = {
  col: "col-lg"
};

FooterNav.propTypes = {
  col: PropTypes.string
};

FooterNav.contextTypes = {
  settings: PropTypes.shape(SettingsProvider.settingDefinition).isRequired
};

export default FooterNav;
