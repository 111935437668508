import React, { Fragment } from "react";
import Route from "react-router-dom/Route";
import Switch from "react-router-dom/Switch";
import loadable from "@loadable/component";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import "./App.css";

import Header from './components/Nav/Header'
import Footer from './components/Nav/Footer'
const NotFound = loadable(() => import('./components/Page/NotFound'))
const MunicipalNews = loadable(() => import('./components/Page/MunicipalityNewsTemplate'))

const HomeContainer = loadable(() => import('./containers/Content/HomeContainer'))
const CampaignContainer = loadable(() => import('./containers/Content/CampaignContainer'))
const CareersContainer = loadable(() => import('./containers/Content/CareersContainer'))
const ContactContainer = loadable(() => import('./containers/Content/ContactContainer'))
const GovernContainer = loadable(() => import('./containers/Content/GovernContainer'))
const GovernNewsContainer = loadable(() => import('./containers/Content/GovernNewsContainer'))
const JobContainer = loadable(() => import('./containers/Content/JobContainer'))
const MemberContainer = loadable(() => import('./containers/Content/MemberContainer'))
const PageContainer = loadable(() => import('./containers/Content/PageContainer'))
const PeopleContainer = loadable(() => import('./containers/Content/PeopleContainer'))
const PolicyContainer = loadable(() => import('./containers/Content/PolicyContainer'))
const PostContainer = loadable(() => import('./containers/Content/PostContainer'))
const ProvinceContainer = loadable(() => import('./containers/Content/ProvinceContainer'))
const SearchContainer = loadable(() => import('./containers/Search/SearchContainer'))

export const App = () => (
  <Fragment>
    <Header />
    <Switch>
      <Route exact path="/" component={HomeContainer} />
      <Route exact path="/:year/:month/:slug" component={PostContainer} />
      <Route exact path="/government/:slug" component={GovernContainer} />
      <Route
        exact
        path="/government/:government/:year/:month/:slug"
        component={GovernNewsContainer}
      />
      <Route exact path="/policy/:slug" component={PolicyContainer} />
      <Route exact path="/province/:slug" component={ProvinceContainer} />
      <Route exact path="/people/:slug" component={MemberContainer} />
      <Route path="/campaigns" component={CampaignContainer} />
      <Route path="/our-people" component={PeopleContainer} />
      <Route exact path="/get-involved/jobs-at-the-da" component={() => {
        window.location.href = 'https://da.mcidirecthire.com/External/CurrentOpportunities';
        return null;
      }} />
      <Route
        path="/get-involved/jobs-at-the-da/:slug"
        component={JobContainer}
      />
      <Route exact path="/newsroom/municipality" component={MunicipalNews} />
      <Route exact path="/jobs" component={CareersContainer} />
      <Route path="/jobs/:slug" component={JobContainer} />
      <Route
        exact
        path="/search"
        render={(routeParams) => (
          <SearchContainer
            showTitle={true}
            {...routeParams}
            refinements={[
              {
                title: "Category",
                attribute: "taxonomies.category",
              },
              {
                title: "Tags",
                attribute: "taxonomies.post_tag",
              },
              {
                title: "Type",
                attribute: "post_type_label",
              },
              {
                title: "Author",
                attribute: "people.member",
              },
            ]}
          />
        )}
      />
      <Route path="/contact" component={ContactContainer} />
      <Route exact path="/not-found" component={NotFound} />
      <Route path="/:slug" component={PageContainer} />

      <Route component={NotFound} />
    </Switch>
    <Footer />
  </Fragment>
);

export default App;
